@tailwind base;
@tailwind components;
@tailwind utilities;
// PrismJS 代码高亮
@import '../../node_modules/prismjs/themes/prism-tomorrow.css';
@import '../../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.css';
// Swiper 幻灯片
@import '../../node_modules/swiper/swiper-bundle.css';

@layer base {
    [lang="zh-cn"] {
        font-family: 'Noto Sans SC Variable', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        .font-title {
            font-weight: 400;
        }
    }
    [lang="en"] {
        font-family: 'Noto Sans SC Variable', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        .font-title {
            font-family: 'Afacad Flux Variable';
            font-weight: 400;
        }
    }
}

:root {
    --primary-text-color: rgb(55, 55, 55);
    --color-darker-gray: #15171a;
}

// 自定义组件代码示例
// 参考：https://tailwindcss.com/docs/reusing-styles

.btn {
    @apply inline-block px-6 py-3
    leading-tight rounded shadow
    hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg
    transition duration-150 ease-in-out;
}

.btn-sm {
    @apply px-3 py-2 text-sm;
}

.btn-pill {
    @apply rounded-[1.6rem]
}

.btn-green {
    @apply text-white bg-ct-green-500 hover:bg-ct-green-700 focus:bg-ct-green-700 active:bg-ct-green-800;
}

.btn-blue {
    @apply text-white bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-800;
}

.btn-confier {
    @apply text-white bg-confier-500 hover:bg-confier-600 focus:bg-confier-600 active:bg-confier-700;
}

.btn-white {
    @apply bg-white hover:bg-gray-50 focus:bg-gray-50 active:bg-gray-50 text-gray-700;
}

.btn-gray {
    @apply bg-gray-300 hover:bg-gray-100 focus:bg-gray-300 active:bg-gray-300 text-gray-700;
}

.icon-bg-green {
    @apply bg-ct-green-100 p-1 inline-block rounded-full fill-ct-green-500;
}

.main-menu {
    @apply flex items-center h-[5rem] py-2;
    div {
        @apply relative;
    }
    div > a.level-1 {
        @apply inline-block px-7 relative z-20 leading-[5rem] w-max;
    }
    .menu-hover {
        @apply border-ct-green-500 border-b-[3px] transition duration-300 ease-in-out hover:cursor-pointer;
    }
    div > a.lang {
        @apply inline-block relative z-10 leading-[3rem] hover:cursor-pointer;
    }
}

.sub-menu {
    @apply absolute z-10 w-max rounded-md bg-white shadow px-[2rem] py-4 mt-[-3px];

    // 让下拉菜单相对父元素居中对齐
    left: 50%;
    transform: translateX(-50%);

    li {
        @apply block border-b;
    }
    li:last-child {
        @apply block border-0;
    }
    a {
        @apply inline-block w-full px-3 py-4 text-[0.9rem] hover:text-gray-500 duration-300 ease-in-out;
    }
}

.sub-menu-solution {
    @apply absolute z-10 w-max rounded-lg bg-white shadow-lg mt-[-3px] grid grid-cols-3 -translate-x-[22%] xl:-translate-x-[30%];
    // 让下拉菜单相对父元素居中对齐
    // left: 50%;
    // transform: translateX(-50%);
    .box {
        @apply xl:px-10 xl:py-8 px-5 py-4;
        li {
            a {
                @apply text-gray-500 hover:text-black duration-300 ease-in-out;
            }
        }
    }
    a {
        @apply inline-block w-full py-3 text-[0.9rem];
    }
    .sub-title {
        @apply border-b block pb-3 text-black text-[0.9rem];
    }
}

.sub-menu-lang {
    @apply absolute z-0 w-max rounded-lg bg-white shadow px-6 mt-[-3px];

    // 让下拉菜单相对父元素居中对齐
    left: 50%;
    transform: translateX(-50%);

    li {
        @apply block border-b;
    }
    li:last-child {
        @apply block border-0;
    }
    a {
        @apply inline-block w-full p-3 text-sm hover:text-gray-500 duration-300 ease-in-out hover:cursor-pointer;
    }
}

#mobile-menu {
    @apply border-t border-gray-300 mt-5;
    a {
        @apply inline-block w-full;
    }
    ul {
        li {
            @apply border-b border-gray-300 block;
            line-height: 4rem;
        }
    }
    .sub {
        @apply bg-gray-50 py-5;
        li {
            @apply border-0 text-[#59585C] text-sm;
            line-height: 3.5rem;
        }
    }
    .level-1 {
        @apply w-full inline-flex items-center;
    }
}

.homepage {
    h1 {
        @apply text-3xl lg:text-5xl;
    }
    h2 {
        @apply leading-none text-2xl lg:text-4xl;
    }
    h3 {
        @apply text-xl;
        font-weight: 350;
    }
    #hero {
        background-image: url('/cms/static/images/home-bg-pure.svg');
        @apply bg-cover bg-center pb-10;
        h1 {
            @apply leading-tight;
            span {
                @apply font-bold text-[2rem] lg:text-[4rem];
            }
        }
    }
    #benefit {
        @apply my-14 lg:my-20;
        .box:last-child {
            @apply border-0;
        }
    }
    #code-sample {
        @apply bg-[#ECEBEB] py-14 lg:py-20 text-sm;
        // h2 {
        //     @apply text-3xl lg:text-4xl text-center lg:text-left;
        // }
        #pc {
            ul {
                @apply my-6;
                li {
                    @apply px-6 py-2 w-3/4 border-b border-b-[#21303D] duration-300 ease-in-out;
                }
                li:last-child {
                    @apply border-0;
                }
            }
            .current {
                // @apply bg-confier-500 text-white arrow-right;
                @apply bg-[#21303D] text-white arrow-right;
            }
        }
        #mobile {
            ul {
                @apply my-10;
                li {
                    @apply first:border-t border-b py-4 border-gray-300 text-gray-800;
                }
            }
        }
        pre[class*="language-"] {
            margin: .5em 0;
            // padding-top: 15px;
            @apply max-h-[400px] lg:pl-10 lg:pt-9 lg:m-0;
            code {
                @apply pr-10;
            }
        }
    }
    #why-juicefs {
        @apply my-14 lg:my-20;
        ul {
            @apply w-fit mx-auto;
            li {
                @apply py-2;
            }
        }
        a {
            @apply text-ct-green-500 hover:underline duration-300 ease-in-out;
        }
        .box {
            @apply border-t-[5px] border-t-ct-green-500 py-2 px-4;
        }
    }
    #call-to-action {
        @apply bg-ct-green-500;
        // h2 {
        //     @apply text-3xl lg:text-4xl text-center;
        // }
        .bg-mobile {
            @apply bg-cover bg-top;
            background-image: url('/cms/static/images/home-call-to-action-mobile.svg');
        }
        .bg-pc {
            @apply bg-contain bg-center bg-no-repeat h-[320px] xl:h-[400px] 2xl:h-[500px];
            background-image: url('/cms/static/images/home-call-to-action.svg');
        }
    }
    #logo-list {
        a.mobile:last-child {
            @apply hidden;
        }
        a.pc:last-child {
            @apply inline-block;
        }
    }
    // 客户墙
    #customer-wall {
        @apply my-20 mb-20;
        .customer {
            @apply border rounded-md text-center p-3 bg-zinc-50 hover:shadow-2xl hover:bg-transparent;
            p {
                @apply mb-8 text-gray-500 leading-6;
                // font-size: 0.89rem;
            }
            img {
                @apply h-20 inline-block py-5;
            }
        }
    }
}

.single {
    h1 {
        @apply text-3xl lg:text-5xl;
    }
    h2 {
        @apply leading-none text-2xl lg:text-4xl; 
    }
    h3 {
        @apply text-xl lg:text-2xl;
    }
    #hero {
        background-image: url('/cms/static/images/cube-bg.svg');
        @apply bg-[#f3f3f3] bg-no-repeat bg-auto bg-top lg:bg-bottom;
        // .title {
        //     @apply text-4xl lg:text-6xl text-center;
        // }
    }
    #content {
        p {
            @apply my-5;
        }
        h2 {
            @apply font-title;
        }
        .gray-1-bg {
            @apply bg-[#FAFAFA];
        }
        .gray-2-bg {
            @apply bg-[#ECEBEB];
        }
        .box {
            @apply container m-auto px-6 py-10 lg:py-16 max-w-3xl;
            ul {
                @apply py-10 list-inside leading-8;
                li {
                    @apply list-item;
                }
            }
            ol {
                @apply py-10 list-inside leading-8;
                li {
                    @apply list-decimal;
                }
            }
        }
        .card-box {
            @apply container m-auto px-6 lg:pb-28;
        }
        .code-box {
            @apply container m-auto px-6 py-10 lg:py-16 prose lg:prose-xl my-[-9rem];
        }
        .person-card {
            @apply rounded-lg bg-white p-7;
            img {
                @apply inline-block rounded-full w-12 my-4;
            }
        }
        .quote-card {
            @apply bg-white rounded-lg p-10 flex flex-col gap-2 mb-10 lg:w-3/4 m-auto;
            img {
                @apply inline-block rounded-full w-12 my-4;
            }
        }
        .product-group {
            @apply grid md:grid-cols-2 gap-8 py-4 mt-10;
            a {
                @apply text-gray-700;
            }

            .product-card {
                @apply border rounded-md text-center p-5 bg-zinc-50 hover:shadow-2xl hover:bg-transparent;
                h3 {
                    @apply text-center font-title;
                }
                p {
                    @apply text-center mx-4;
                }
            }
        }
    }
    #call-to-action {
        @apply bg-confier-500;
        h2 {
            @apply text-4xl;
        }
        .bg-mobile {
            @apply bg-contain bg-no-repeat bg-center h-[350px];
            background-image: url('/cms/static/images/dashed-line.svg');
        }
        .bg-pc {
            @apply bg-contain bg-bottom bg-no-repeat h-[350px];
            background-image: url('/cms/static/images/dashed-line.svg');
        }
    }
}

.blog {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    #hero {
        background-image: url('/cms/static/images/cube-bg.svg');
        @apply bg-[#f3f3f3] bg-no-repeat bg-auto bg-top lg:bg-bottom;
        .title {
            @apply text-4xl lg:text-6xl text-center;
        }
    }
    #category {
        @apply py-8 text-base text-zinc-500 mx-auto w-fit;
        a {
            @apply hover:text-zinc-800 hover:font-bold;
        }
        span {
            @apply mx-6;
        }
    }
    #content {
        @apply pb-16;
        font-weight: 400;
        background-color: #fcfcfc;
        .extra-info {
            @apply text-sm text-gray-500 self-end space-x-5;
            .info-tab {
                @apply inline-flex gap-1;
            }
        }
        .js-toc {
            @apply mx-2;
            .toc-list {
                @apply text-sm leading-7 list-outside list-disc text-zinc-500 pl-5;
                .toc-list {
                    @apply underline;
                }
                a {
                    @apply text-zinc-500;
                    text-decoration: none;
                    &:hover {
                        color: #1a202c;
                    }
                }
            }
        }
        .entry {
            @apply mt-6 mb-8;
            color: var(--primary-text-color);
            line-height: 1.8rem;
            h1, h2, h3, h4, h5, h6 {
                @apply mb-2 pt-2;
                color: var(--color-darker-gray);
                font-weight: 800;
                letter-spacing: -.02em;
                line-height: 1.15;
                
            }
            p {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                margin-bottom: 1.25rem;
            }
            a {
                @apply text-ct-green-500 no-underline hover:border-b-2 duration-150 border-b-green-500 ease-in-out border-dashed;
            }
            h2 {
                font-size: 1.6rem;
            }
            h3 {
                font-size: 1.4rem;
            }
            h4 {
                font-size: 1.2rem;
            }
            h5 {
                font-size: 1rem;
            }
            h6 {
                font-size: 0.8rem;
            }
            p+ul, p+ol {
                @apply py-0 mt-[-10px];
            }
            ul+h1, ul+h2, ul+h3, ol+h1, ol+h2, ol+h3 {
                @apply mt-0;
            }
            ul, ol {
                @apply pl-4 mb-8;
                li {
                    @apply py-0 mb-2;
                }
                li:last-child {
                    @apply mb-1;
                }
                ul {
                    list-style-type: circle;
                    @apply pt-0 pb-1 mb-1;
                }
            }
            ul {
                @apply list-disc list-outside ml-4;
            }
            ol {
                @apply list-decimal list-outside ml-4;
            }
            img {
                @apply pt-2 pb-4 mx-auto;
            }
            img.author {
                @apply pt-0 pb-0 h-16 rounded-full;
            }
            blockquote {
                @apply bg-zinc-100 px-8 py-4 my-6 border-l-4 border-ct-green-500 shadow;
            }
            pre {
                @apply mt-4 mb-8 overflow-y-scroll text-sm;
                code {
                    @apply bg-transparent;
                }
            }
            code {
                @apply bg-zinc-200 rounded-[4px] break-normal;
            }
            iframe {
                @apply w-full h-[230px] sm:h-[380px] lg:w-[640px] lg:h-[480px] border mx-auto my-10;
            }
            table {
                @apply table-auto border-collapse w-fit border border-slate-400 bg-white shadow-sm mb-4 mt-2 break-normal;
                thead {
                    @apply bg-slate-50;
                    th {
                        @apply border border-slate-300 px-2 py-1 md:p-4 text-slate-700 text-sm md:text-base text-left;
                    }
                }
                tbody {
                    td {
                        @apply w-1/2 md:w-fit border border-slate-300 px-2 py-1 md:p-4 text-slate-500 text-sm;
                    }
                    th {
                        @apply w-1/2 md:w-fit border border-slate-300 px-2 py-1 md:p-4 text-slate-700 bg-slate-50 text-sm font-bold;
                    }
                }
                caption {
                    @apply text-xl pb-2 text-slate-600;
                }
            }
        }
        .entry-meta {
            @apply text-sm space-y-2;
            a {
                @apply text-ct-green-500 hover:text-gray-600;
            }
            .badge {
                @apply border py-1 px-2 rounded-md bg-zinc-100 inline-block my-1;
            }   
        }
        .pagination {
            @apply container m-auto text-center my-10;
            .number {
                @apply inline-block pt-1 w-8 h-8 align-middle border rounded-full text-gray-600 text-base;
            }
            .active {
                @apply bg-ct-green-500 text-white;
            }
        }
    }
}

.event {
    #hero {
        background-image: url('/cms/static/images/cube-bg.svg');
        @apply bg-[#f3f3f3] bg-no-repeat bg-auto bg-top lg:bg-bottom;
        .title {
            @apply text-4xl text-center;
        }
        .extra-info {
            @apply text-gray-500 space-x-5 text-center mt-10;
            .info-tab {
                @apply inline-flex gap-1;
            }
        }
    }
    #event-list {
        .entry {
            a.title {
                @apply inline-block hover:text-ct-green-900 duration-150 ease-in-out;
            }
            a.detail {
                @apply text-sm text-ct-green-500 hover:border-b border-b-ct-green-500 duration-150 ease-in;
            }
            .extra-info {
                @apply text-sm text-gray-500 self-end;
                .info-tab {
                    @apply inline-flex gap-1;
                }
            }
        }
        #pagination {
            @apply container m-auto px-6 text-center mb-10;
            .number {
                @apply inline-block pt-1 w-9 h-9 align-middle border rounded-full text-gray-600;
            }
            .active {
                @apply bg-ct-green-500 text-white;
            }
        }
    }
}

.formpage {
    input, textarea {
        @apply mt-2 py-2 px-4 rounded-lg bg-white border border-gray-400 text-gray-400 font-normal focus:border-ct-green-600 focus:outline-none w-full;
    }
    label {
        @apply inline-block mb-2;
        span {
            @apply text-red-500 pr-2;
        }
    }
    .captcha {
        @apply inline-block;
    }
    .captcha~input {
        @apply w-40 ml-5;
    }
    .box-pure {
        @apply container m-auto px-6 py-10 lg:py-16;
    }
    .social-links {
        @apply space-y-5 pt-5;
        li {
            @apply pl-5 block;
        }
        a {
            @apply text-base flex;
        }
        span {
            @apply pl-4;
        }
    }
}

.community-page {
    h1 {
        @apply text-3xl lg:text-5xl;
    }
    h2 {
        @apply leading-none text-2xl lg:text-4xl; 
    }
    h3 {
        @apply text-xl lg:text-2xl;
    }
    h4 {
        @apply text-xl font-normal;
    }
    #header {
        background-image: url('/cms/static/images/home-bg.png');
        @apply bg-cover bg-center pb-10;
    }
    #channel {
        @apply bg-zinc-50 py-20;
        .card {
            @apply bg-white shadow-md rounded-lg p-6 hover:scale-105 duration-150 ease-in-out text-center relative;
        }
        .icon {
            @apply inline-block h-24 self-center my-2 px-0 text-center;
        }
        .description {
            @apply text-gray-500 pt-2 pb-10 text-left;
        }
        .link { 
            @apply pb-6 text-ct-green-600 inline-block hover:text-ct-green-900 absolute inset-x-0 bottom-0;
        }
    }
    #activity {
        @apply py-20;
        .icon {
            @apply inline-block h-36 self-center my-2 px-0 text-center;
        }
        .card {
            @apply p-6 hover:bg-zinc-100 hover:shadow-md hover:rounded-lg hover:scale-105 duration-150 ease-in-out text-center relative;
        }
        p {
            @apply text-left py-2;
        }
        p.gap {
            @apply pb-14;
        }
    }
    #subscribe {
        @apply bg-gradient-to-r from-indigo-100 via-green-200 to-purple-100 py-16;
        .icon {
            @apply inline-block h-32 self-center px-0;
        }
        .link {
            @apply inline-block py-10 px-16 hover:shadow-lg hover:rounded-lg hover:scale-105 duration-150 ease-in-out;
        }
    }
    #event {
        @apply mt-16;
        a.detail {
            @apply text-sm text-ct-green-500 hover:border-b border-b-ct-green-500 duration-150 ease-in;
        }
        .extra-info {
            @apply text-sm text-gray-500 self-end;
            .info-tab {
                @apply inline-flex gap-1;
            }
        }
    }
    #office-hour {
        @apply bg-[#f3f3f3] py-20 ;
        a {
            @apply text-ct-green-500 no-underline hover:border-b-2 duration-150 border-b-green-500 ease-in-out border-dashed break-all;
        }
        .content-box {
            @apply space-y-5;
            img {
                @apply inline-block;
            }
            p {
                @apply leading-relaxed text-left;
            }
        }
    }
    #call-to-action {
        @apply bg-ct-green-500;
        .bg-mobile {
            @apply bg-cover bg-top;
            background-image: url('/cms/static/images/folder-bg.svg');
        }
        .bg-pc {
            @apply bg-cover bg-bottom h-[390px];
            background-image: url('/cms/static/images/folder-bg.svg');
        }
    }
}

.download {
    #hero {
        background-image: url('/cms/static/images/cube-bg.svg');
        @apply bg-[#f3f3f3] bg-no-repeat bg-auto bg-top lg:bg-bottom;
        .title {
            @apply text-4xl lg:text-6xl text-center;
        }
    }
    #download-box {
        min-height: 75vh;
        pre {
            @apply my-5;
            code {
                @apply scroll-bar;
            }
        }
        .paragraph {
            a {
                @apply text-ct-green-500 no-underline hover:border-b-2 duration-150 border-b-green-500 ease-in-out border-dashed;
            }
            ol {
                @apply list-inside list-decimal leading-8;
            }
            ul {
                @apply list-inside list-item leading-8;
            }
            p {
                @apply mb-5;
            }
            i {
                @apply text-sm;
            }
        }
    }
}

.community-edition, .enterprise-edition {
    h1 {
        @apply text-3xl lg:text-5xl;
    }
    h2 {
        @apply leading-none text-2xl lg:text-4xl;
    }
    h3 {
        @apply text-xl lg:text-2xl;
    }

    // Feature 内容块列表样式
    .feature-list {
        ul {
          @apply space-y-4 py-6 list-disc list-inside;
          li {
            @apply text-lg leading-relaxed;
          }
        }
      }
    #header {
        background-image: url('/cms/static/images/home-bg.png');
        @apply bg-cover bg-center pb-10;
    }
    #intro {
        @apply py-10 lg:py-20;
        .box {
            @apply grid lg:grid-cols-2 items-center gap-7 mb-10 lg:mb-32;
            img {
                @apply inline-block;
            }
            h2 {
                @apply text-center lg:text-left text-2xl lg:text-4xl;
            }
            p {
                @apply py-6;
            }
            .block-text {
                @apply text-center lg:text-left;
            }
            .block-image {
                @apply text-center;
            }
        }
    }
    #case {
        @apply py-20 bg-ct-green-500;
        .box {
            @apply flex flex-col lg:px-10 text-white;
            h2 {
                @apply text-2xl lg:text-4xl mb-4;
            }
            p {
                @apply my-2;
            }
        }
    }
    #join {
        @apply bg-[#fafafa] py-20;
        .box {
            @apply text-center;
            div {
                @apply py-6;
            }
        }
        img {
            @apply inline-block rounded-full w-12;
        }
    }
    .icon {
        @apply inline-block p-2 rounded-full  bg-[#ebebeb] hover:bg-[#dfdfdf] duration-300 ease-in-out;
    }
    #call-to-action {
        @apply bg-ct-green-500;
        h2 {
            @apply text-3xl lg:text-4xl text-center;
        }
        .bg-mobile {
            @apply bg-cover bg-top;
            background-image: url('/cms/static/images/folder-bg.svg');
        }
        .bg-pc {
            @apply bg-cover bg-bottom h-[390px];
            background-image: url('/cms/static/images/folder-bg.svg');
        }
    }
    #contribute {
        @apply py-20 bg-zinc-50;
        .box {
            @apply prose lg:prose-xl  prose-a:text-ct-green-500 prose-a:no-underline hover:prose-a:border-b-2 prose-a:duration-150 prose-a:border-b-green-500 prose-a:ease-in-out prose-a:border-dashed prose-li:text-lg prose-p:text-base;
        }
        ul {
            li {
                list-style: none;
            }
            li::before {
                content: '';
                display: inline-block;
                height: 21px;
                width: 21px;
                margin-left: -2rem;
                margin-right: 1rem;
                margin-bottom: -4px;
                background-image: url('/cms/static/images/correct-green.svg');
                background-repeat: no-repeat;
            }
        }
        .new-ul {
            @apply md:py-10 ml-10 space-y-6;
        }
        .new-li {
            @apply text-lg;
            p {
                @apply text-sm text-zinc-500 py-2;
            }
            a {
                @apply text-ct-green-500 hover:text-ct-green-600 hover:underline;
            }
        }
    }
    #related {
        @apply py-20;
        .box {
            @apply flex flex-col lg:px-10 self-center;
            h2 {
                @apply text-2xl lg:text-4xl mb-4;
            }
            p {
                @apply my-2;
            }
        }
        .swiper-button-prev, .swiper-button-next {
            @apply text-gray-500 hover:text-gray-300;
        }
        .swiper-pagination-bullet-active {
            @apply bg-green-500;
        }
    }
}

.enterprise-edition {
    .feature-list {
        ul {
            @apply space-y-4 py-6 pl-5 list-disc list-outside;
            li {
              @apply text-lg leading-tight;
            }
        }
        h2 {
            @apply py-5;
        }
        a {
            @apply text-green-600;
            // 添加 hover 状态
            &:hover {
                @apply underline;
            }
        }
    }
    .gray-1-bg {
        @apply bg-[#FAFAFA];
    }
}

.cloud-service {
    h1 {
        @apply text-3xl lg:text-5xl;
    }
    h2 {
        @apply leading-none text-2xl lg:text-4xl; 
    }
    h3 {
        @apply text-xl lg:text-2xl;
    }
    #header {
        @apply bg-[#f3f3f3] pb-10 lg:pb-14 bg-cover bg-center;
        background-image: url('/cms/static/images/home-bg.png');

    }
    #cloud-list {
        @apply  lg:my-16 py-10;
        h2 {
            @apply text-2xl lg:text-4xl;
        }
    }
    #benefit {
        @apply py-10 lg:py-20 bg-[#FAFAFA];
        .box:last-child {
            @apply border-0;
        }
        h2 {
            @apply text-2xl lg:text-4xl;
        }
        h3 {
            @apply text-2xl;
        }
    }
    #adopter {
        @apply py-10 lg:py-20 bg-[#FAFAFA];
        h2 {
            @apply text-2xl lg:text-4xl;
        }
    }
    #pricing {
        @apply pt-16 pb-24;
        .box {
            @apply bg-[#F4F4F5] text-center py-8 rounded-md drop-shadow-lg;
            .num {
                @apply text-3xl xl:text-5xl;
            }
            ul {
                min-height: 170px;
            }
            .description {
                ul {
                    @apply mb-10 px-5 ml-5 text-left list-outside list-disc space-y-2;
                }
                p {
                    @apply text-left pl-5 pb-2 font-bold text-base;
                }
            }
        }
        .bg {
            @apply bg-[#353535] text-white;
        }
    }
    #comparison {
        @apply py-10 ;
        table {
            @apply table-fixed w-full;
            thead {
                th {
                    @apply text-center py-2 text-sm md:text-base;
                }
            }
            tbody {
                @apply text-sm;
                tr {
                    @apply border-b hover:bg-gray-100;
                }
                td {
                    @apply py-3 text-zinc-600 first:text-black;
                }
                td:first-child {
                    font-weight: 500;
                }
            }
        }
    }
    #call-to-action {
        @apply bg-ct-green-500;
        .bg-mobile {
            @apply bg-cover bg-top h-[500px];
            background-image: url('/cms/static/images/folder-bg.svg');
        }
        .bg-pc {
            @apply bg-cover bg-bottom h-[390px];
            background-image: url('/cms/static/images/folder-bg.svg');
        }
    }
    #case {
        @apply py-20;
        .box {
            @apply flex flex-col lg:px-10 self-center;
            h2 {
                @apply text-2xl lg:text-4xl mb-4;
            }
            p {
                @apply my-2;
            }
        }
        .swiper-button-prev, .swiper-button-next {
            @apply text-gray-500 hover:text-gray-300;
        }
        .swiper-pagination-bullet-active {
            @apply bg-green-500;
        }
    }
    
    // 云市场卡片样式
    .marketplace-card {
        @apply border border-gray-200 rounded-lg overflow-hidden transition-all duration-300 h-full flex flex-col shadow-md;
        
        &:hover {
            @apply -translate-y-1 shadow-xl;
        }
        
        .card-logo {
            @apply p-8 flex items-center justify-center bg-gray-50 border-b border-gray-200 h-[100px];
        }
        
        .card-content {
            @apply p-6 flex-grow flex flex-col bg-white;
            
            .card-title {
                @apply text-xl font-semibold text-gray-900 mb-3;
            }
            
            .card-description {
                @apply text-gray-500 flex-grow mb-6 leading-relaxed;
            }
        }
    }
    
    .marketplace-cards-section {
        @apply py-8 my-8;
        
        h3 {
            @apply mb-6;
        }
    }
    
    .marketplace-cards-grid {
        @apply grid gap-10;
        
        @screen md {
            @apply grid-cols-2;
        }
        
        @screen lg {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }
    }
    
    // blockquote 样式
    #features blockquote {
        @apply my-6 py-4 px-6 bg-gray-50 border-l-4 border-ct-green-500 rounded-r text-gray-600 italic shadow-sm;
        
        p {
            @apply m-0 leading-relaxed;
            
            &:last-child {
                @apply mb-0;
            }
        }
        
        cite {
            @apply block mt-3 text-sm text-gray-500 not-italic text-right;
        }
    }
    
    @media (max-width: 768px) {
        #features blockquote {
            @apply py-3 px-4 my-4;
        }
    }
    
    .info-note {
        @apply text-sm text-gray-500 text-center border-b pb-1 mx-auto block w-fit italic pt-2;
        
        p {
            @apply mb-2 last:mb-0;
        }
        
        a {
            @apply text-ct-green-500 underline hover:text-ct-green-600;
        }
        
        strong, b {
            @apply text-gray-600;
        }
        
        ul, ol {
            @apply pl-5 mb-2;
            
            li {
                @apply mb-1;
            }
        }
    }
}

.solution {
    h1 {
        @apply text-3xl lg:text-5xl;
    }
    h2 {
        @apply leading-none text-2xl lg:text-4xl; 
    }
    h3 {
        @apply text-xl lg:text-2xl;
    }
    word-wrap: break-word;
    #content {
        .paragraph {
            .box {
                ul {
                    @apply space-y-5 py-10;
                }
                ul li {
                    @apply flex items-start list-none text-lg;
                }
                ul li::before {
                    content: url('/cms/static/images/correct.svg'); /* 添加 SVG 图标 */
                    margin-right: 15px; /* 添加右间距 */
                    margin-top: 5px;
                }
            }
        }
        .two-column {
            @apply pb-20;
            .box {
                @apply py-0; 
                ul {
                    @apply list-inside py-0 mt-0; // 添加列表样式和内边距
                    li {
                        @apply py-2 text-lg; // 添加上下内边距和文本颜色
                        &:before {
                            content: url('/cms/static/images/correct.svg'); // 添加 SVG 图标
                            margin-right: 10px; // 添加右间距
                        }
                    }
                }
            }
        }
        h2, h3 {
            @apply font-title;
        }
        figure {
            @apply mb-20;
        }
        a {
            @apply text-green-600;
            // 添加 hover 状态
            &:hover {
                @apply underline;
            }
        }
    }
    #case {
        @apply py-20;
        .box {
            @apply flex flex-col lg:px-10 self-center;
            p {
                @apply my-2;
            }
        }
        .swiper-button-prev, .swiper-button-next {
            @apply text-gray-500 hover:text-gray-300;
        }
        .swiper-pagination-bullet-active {
            @apply bg-green-500;
        }
    }
    #call-to-action {
        @apply bg-ct-green-500;
        .bg-mobile {
            @apply bg-contain bg-no-repeat bg-center h-[350px];
            background-image: url('/cms/static/images/dashed-line.svg');
        }
        .bg-pc {
            @apply bg-contain bg-bottom bg-no-repeat h-[350px];
            background-image: url('/cms/static/images/dashed-line.svg');
        }
    }
}

// Prism 代码高亮
pre[class*="language-"] {
    @apply max-h-[500px];
    margin: .5em 0;
    padding-top: 15px;
    code {
        @apply pr-10;
    }
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #061621;
}

pre.codehilite {
    @apply rounded;
}

.line-numbers .line-numbers-rows {
    border-right: none;
    background-color: #21303D;
    margin-top: -15px;
    padding-top: 15px;
    padding-bottom: 15px;
    letter-spacing: 3px;

    span:before {
        content: counter(linenumber);
        color: rgb(111, 111, 111);
        display: block;
        padding-right: 0.8em;
        text-align: right;
    }
}

.arrow-right {
    @apply relative;
}
.arrow-right::before {
    @apply absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    // border-left: 10px solid #b6d145;
    border-left: 10px solid #21303D;
    left: 100%;
    top: 50%;
    margin-top: -7px;
}

.arrow-down {
    @apply relative;
}
.arrow-down::before {
    @apply absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #ffffff;
    top: 100%;
    left: 35%;
    margin-bottom: -7px;
}

.arrow-up {
    @apply relative;
}
.arrow-up::before {
    @apply absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #ffffff;
    bottom: 100%;
    right: 10%;
    // margin-bottom: -7px;
}

#message {
    @apply fixed w-full z-50;
    p {
        @apply inline;
    }
}

// message type
.success {
    @apply bg-ct-green-600 text-white;
}
.warning {
    @apply bg-yellow-300 text-black;
}
.danger {
    @apply bg-red-500 text-white;
}

#footer {
    @apply bg-[#212827] pt-10 pb-8 text-white;
    .social-icon {
        @apply inline-block p-2 rounded-full border hover:bg-neutral-700 duration-300 ease-in-out;
    }
    .flat-menu {
        @apply mb-10;
        .title {
            @apply text-[#71717a] mb-6 text-xl;
        }
        ul {
            @apply leading-[2.6rem];
        }
    }
}

.icon {
    @apply pl-6 pb-6;
    background-size: 24px;
}
.github {
    background: url(/cms/static/images/icons/github.svg) no-repeat;
}
.slack {
    background: url(/cms/static/images/icons/slack.svg) no-repeat;
}
.wechat {
    background: url(/cms/static/images/icons/wechat.svg) no-repeat;
}
.mail {
    background: url(/cms/static/images/icons/mail.svg) no-repeat;
}
.correct {
    background: url(/cms/static/images/correct-green.svg) no-repeat;
}
.wrong {
    background: url(/cms/static/images/wrong.svg) no-repeat;
}

.scroll-bar::-webkit-scrollbar {
    height: 4px;
}
.scroll-bar::-webkit-scrollbar-thumb {
    border-radius:5px;
    background:rgba(0,0,0,0.2);
}
.scroll-bar::-webkit-scrollbar-track {
    border-radius:0;
    background:rgba(0,0,0,0.1);
}

.copy-code {
    @apply absolute top-0 bottom-0 right-1 py-2 px-2;
    svg {
        @apply hover:bg-gray-300;
    }
}

[x-cloak] {
    display: none;
}
