@import url('/node_modules/instantsearch.css/themes/reset-min.css');

.search {
    #search-box-tips {
        @apply text-center mt-4 text-sm md:text-base;
        a {
            @apply text-ct-green-500 no-underline hover:border-b-2 duration-150 border-b-green-500 ease-in-out border-dashed;
        }
    }

    .ais-SearchBox {
        .ais-SearchBox-form {
            @apply text-center;

            .ais-SearchBox-input {
                @apply rounded-l-full w-48 sm:w-3/5 h-[40px] border border-gray-200 px-5 focus:outline-0 focus:outline focus:outline-ct-green-500;
            }
            .ais-SearchBox-submit {
                @apply bg-ct-green-500 h-10 w-16 md:w-28 rounded-r-full text-white text-center leading-normal;
            }
            .ais-SearchBox-submitIcon {
                @apply fill-white inline h-5 w-5;
            }
            .ais-SearchBox-resetIcon {
                // @apply ml-[-90px] md:ml-[-146px] fill-inherit;
                @apply hidden;
            }
        }
    }

    .ais-Menu-list {
        @apply flex border-b-2 items-center mb-12;

        .ais-Menu-item {
            @apply block pb-4 px-4 mb-[-2px];
        }
        .ais-Menu-item--selected {
            @apply border-b-2 border-ct-green-500 text-ct-green-500;
        }
    }

    .ais-Hits-list {
        @apply block;

        .ais-Hits-item, .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Results-item {
            @apply block w-full;
        }

        .item {
            @apply block my-8;
        }
        .title {
            @apply text-xl;
        }
        .fragment {
            @apply text-base text-zinc-500 py-4 leading-6;
        }
        .ais-Highlight-highlighted, .ais-Snippet-highlighted {
            @apply text-ct-green-500 font-bold px-1;
        }
    }

    .ais-Pagination {
        .ais-Pagination-list {
            @apply space-x-2 flex justify-center;
        }
        .ais-Pagination-link {
            @apply inline-block pt-1 w-9 h-9 align-middle border rounded-full text-gray-600;
        }
        .ais-Pagination-item--selected {
            .ais-Pagination-link {
                @apply bg-ct-green-500 text-white;
            }
        }
        .ais-Pagination-item--disabled {
            .ais-Pagination-link {
                @apply bg-gray-100;
            }
        }
    }
}
